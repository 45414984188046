<template>
    <div class="container-fluid">
        <div class="container">
            <ul>
            <li>Created At: {{product.created_at}}</li>
            <li>Updated At: {{product.updated_at}}</li>
            <li>SKU: {{product.sku}}</li>
        </ul>
        <card>
        <template v-slot:headerTitle>
                        <h4 class="card-title">Form Grid</h4>
               </template>
                  <template v-slot:body>
                     <form>
                        <div class="row">
                           <div class="col">
                            <label for="exampleInputDisabled5">Product Name</label>
                              <input type="text" v-model="product.name" class="form-control" >
                           </div>
                           <div class="col">
                            <label for="exampleInputDisabled5">Part No / SKU</label>
                              <input type="text" readonly="readonly" v-model="product.sku" class="form-control" placeholder="Last name">
                           </div>
                        </div>
                        <div class="row mt-3">
                           <div class="col">
                            <label for="exampleInputDisabled5">Price</label>
                              <input type="text" v-model="product.price" class="form-control" >
                           </div>
                           <div class="col">
                            <label for="exampleInputDisabled5">Status</label>
                              <input type="text" v-model="product.status" class="form-control" placeholder="Last name">
                           </div>
                        </div>
                     </form>
                  </template>
            </card>
            <card>
        <template v-slot:headerTitle>
                        <h4 class="card-title">Custom Attributes</h4>
               </template>
                  <template v-slot:body>
                     <form>
                        <div class="row">
                            <textT type="description"/>
                        </div>
                     </form>
{{product}}
<b-button @click="saveProduct()" variant="primary" class="mt-2 mr-1">Save</b-button>

                  </template>
            </card>
    </div>
</div>
</template>
<script>
import textT from '../../../components/omni/text.vue'
export default {
    name: 'OrderView',
    components: {
        textT
    },
    data() {
    return{
    sku: '',
    name: "this.product.name",
    fields: [],
    }
    },
    computed: {
        product(){
            let productData = this.$store.state.products.product
            return productData
        },
        customAtts(){
        let productData = this.$store.state?.products?.product
        const att = productData?.custom_attributes
        return att
        },
    },
    methods: {
        saveProduct(){
           this.$store.dispatch('products/saveProduct')
        },
        findValues(code){
        let productData = this.$store.state?.products?.product
        const valuea = productData?.custom_attributes?.find(
        (info) => info.attribute_code === code);
        return valuea?.value
        }
    },
    beforeMount() {
        this.$store.dispatch('products/fetchProduct', {
            sku: this.$route.params.sku
        });
    },
    beforeDestroy () {
        this.$store.commit('setThirdPartyPaymentInfo', '');
    }
}
</script>