<template>
    <div>
        <div class="form-group" v-for="(attribute, index) in customAttributes" :key="index">
            <div class="editable-field" v-if="showAttribute(attribute.attribute_code)">
                <label :for="attributeType(attribute.attribute_code)">{{ attribute.attribute_code }}</label>
                <textarea v-if="attributeType(attribute.attribute_code) === 'textarea'" v-model="attribute.value"
                    rows="3" class="form-control"></textarea>
                <input v-else-if="attributeType(attribute.attribute_code) === 'text'" v-model="attribute.value" rows="3"
                    class="form-control" />
                <div v-else-if="attributeType(attribute.attribute_code) === 'boolean'"
                    class="custom-control custom-switch custom-control-inline">
                    <input v-model="attribute.value" type="checkbox" class="custom-control-input" id="customSwitch2"
                        checked="">
                    <label class="custom-control-label" for="customSwitch2">Active Switch</label>
                </div>
                <div v-else-if="attributeType(attribute.attribute_code) === 'price'" class="input-group mb-4">
                    <div class="input-group-prepend"><span class="input-group-text">$</span></div><input
                        v-model="attribute.value" type="text" aria-label="Amount (to the nearest dollar)"
                        class="form-control">
                    <div class="input-group-append"><span class="input-group-text">.00</span></div>
                </div>
                <treeselect :multiple="false" v-else-if="attributeType(attribute.attribute_code) === 'select'"
                    :options="optionsList(attribute.attribute_code)" :placeholder="attribute.value"
                    v-model="attribute.value" :normalizer="normalizer" />
                <treeselect :multiple="true" v-else-if="attributeType(attribute.attribute_code) === 'multiselect'"
                    :options="optionsList(attribute.attribute_code)" placeholder="" :value="toArray(attribute.value)"
                    @input="updateValue" :normalizer="normalizer" :instanceId="attribute.attribute_code"/>
            </div>
        </div>
    </div>
</template>
<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapGetters } from 'vuex'
export default {
    components: { Treeselect },
    props: {

    },
    data() {
        return {
            options: [
                {
                    "value": "2",
                    "label": "test"
                },
                {
                    "value": "30355",
                    "label": "Lifestyle"
                },
                {
                    "value": "30353",
                    "label": "Access"
                }
            ],
            optionValues: ["30355"],
            product_group: [],
            normalizer(node) {
                return {
                    id: node.value,
                    label: node.label
                }
            }
        }
    },
    mounted() {
        this.product_group = [30355]
        
    },
    methods: {
        attributeType(code) {
            const checkType = this.allAttributes.find((attr) => attr.attribute_code === code)
            return checkType?.frontend_input
        },
        optionsList(code) {
            const checkType = this.allAttributes.find((attr) => attr.attribute_code === code)
            return checkType?.options
        },
        showAttribute(attcode) {
            const checkWhitelist = this.whitelist.attributes.find((attr) => attr.code === attcode)
            if (checkWhitelist) {
                return true
            } else {
                return false
            }
        },
        toArray(value) {
            var names = value;
            var nameArr = names.split(',');
            return nameArr
        },
        updateValue(value, instanceId) {
        this.$store.commit('products/updateCustomAttribute', { value, instanceId})
          console.log(value + instanceId)
    },
    },
    computed: {
        ...mapGetters({
            customAttributes: 'products/getCustomAttributes',
            allAttributes: 'products/getAttributes',
            whitelist: 'getUser'
        })
    }
}
</script>